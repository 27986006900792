define(["@loader","npm-extension","module"], function(loader, npmExtension, module){
npmExtension.addExtension(loader);
if(!loader.main){ loader.main = "hos@0.0.1#index"; }
loader._npmExtensions = [].slice.call(arguments, 2);
(function(loader, packages, options){
	var g = loader.global;
	if(!g.process) {
		g.process = {
			argv: [],
			cwd: function(){
				var baseURL = loader.baseURL;
				return baseURL;
			},
			browser: true,
			env: {
				NODE_ENV: loader.env
			},
			version: '',
			platform: (navigator && navigator.userAgent && /Windows/.test(navigator.userAgent)) ? "win" : ""
		};
	}

	if(!loader.npm) {
		loader.npm = {};
		loader.npmPaths = {};
		loader.globalBrowser = {};
	}
	if(!loader.npmParentMap) {
		loader.npmParentMap = options.npmParentMap || {};
	}
	var rootPkg = loader.npmPaths.__default = packages[0];
	var rootConfig = rootPkg.steal || rootPkg.system;
	var lib = rootConfig && rootConfig.directories && rootConfig.directories.lib;

	var setGlobalBrowser = function(globals, pkg){
		for(var name in globals) {
			loader.globalBrowser[name] = {
				pkg: pkg,
				moduleName: globals[name]
			};
		}
	};
	var setInNpm = function(name, pkg){
		if(!loader.npm[name]) {
			loader.npm[name] = pkg;
		}
		loader.npm[name+"@"+pkg.version] = pkg;
	};
	var forEach = function(arr, fn){
		var i = 0, len = arr.length;
		for(; i < len; i++) {
			res = fn.call(arr, arr[i], i);
			if(res === false) break;
		}
	};
	var setupLiveReload = function(){
		if(loader.liveReloadInstalled) {
			loader["import"]("live-reload", { name: module.id })
			.then(function(reload){
				reload.dispose(function(){
					var pkgInfo = loader.npmContext.pkgInfo;
					delete pkgInfo[rootPkg.name+"@"+rootPkg.version];
					var idx = -1;
					forEach(pkgInfo, function(pkg, i){
						if(pkg.name === rootPkg.name &&
							pkg.version === rootPkg.version) {
							idx = i;
							return false;
						}
					});
					pkgInfo.splice(idx, 1);
				});
			});
		}
	};

	var ignoredConfig = ["bundle", "configDependencies", "transpiler"];
	packages.reverse();
	forEach(packages, function(pkg){
		var steal = pkg.steal || pkg.system;
		if(steal) {
			// don't set steal.main
			var main = steal.main;
			delete steal.main;
			var configDeps = steal.configDependencies;
			if(pkg !== rootPkg) {
				forEach(ignoredConfig, function(name){
					delete steal[name];
				});
			}

			loader.config(steal);
			if(pkg === rootPkg) {
				steal.configDependencies = configDeps;
			}
			steal.main = main;
		}
		if(pkg.globalBrowser) {
			var doNotApplyGlobalBrowser = pkg.name === "steal" &&
				rootConfig.builtins === false;
			if(!doNotApplyGlobalBrowser) {
				setGlobalBrowser(pkg.globalBrowser, pkg);
			}
		}
		var systemName = steal && steal.name;
		if(systemName) {
			setInNpm(systemName, pkg);
		} else {
			setInNpm(pkg.name, pkg);
		}
		if(!loader.npm[pkg.name]) {
			loader.npm[pkg.name] = pkg;
		}
		loader.npm[pkg.name + "@" + pkg.version] = pkg;
		var pkgAddress = pkg.fileUrl.replace(/\/package\.json.*/, "");
		loader.npmPaths[pkgAddress] = pkg;
	});
	setupLiveReload();
	forEach(loader._npmExtensions || [], function(ext){
		// If there is a systemConfig use that as configuration
		if(ext.systemConfig) {
			loader.config(ext.systemConfig);
		}
	});
})(loader, [
 {
  "name": "hos",
  "version": "0.0.1",
  "fileUrl": "./package.json",
  "steal": {
   "transpiler": "babel",
   "babelOptions": {
    "presets": [
     "es2015",
     "stage-0"
    ]
   },
   "bundle": [
    "src/components/dvir/reports/dvir/dvir"
   ],
   "paths": {
    "bootstrap@3.3.7#dist/js/npm": "node_modules/bootstrap/dist/js/bootstrap.js",
    "bootstrap-dialog@1.34.6#src/js/bootstrap-dialog": "node_modules/bootstrap-dialog/dist/js/bootstrap-dialog.js",
    "dragAndDrop": "src/assets/lib/jquery-ui/jquery-ui.min.js",
    "inputMask": "src/assets/lib/input-mask/jquery.inputmask.bundle.js",
    "jquery@2.2.4#dist/jquery": "node_modules/jquery/dist/jquery.js",
    "js-cookie@2.1.4#src/js.cookie": "node_modules/js-cookie/src/js.cookie.js",
    "node-waves@0.7.5#src/js/waves": "node_modules/node-waves/dist/waves.js"
   },
   "meta": {
    "bootstrap@3.3.7#dist/js/npm": {
     "format": "global",
     "deps": [
      "jquery"
     ]
    },
    "bootstrap-dialog@1.34.6#src/js/bootstrap-dialog": {
     "format": "global",
     "exports": "BootstrapDialog",
     "deps": [
      "bootstrap"
     ]
    },
    "bootstrap-multiselect": {
     "format": "global"
    },
    "dragAndDrop": {
     "deps": [
      "jquery"
     ]
    },
    "inputMask": {
     "deps": [
      "jquery"
     ],
     "format": "global"
    },
    "jquery@2.2.4#dist/jquery": {
     "format": "global",
     "exports": "jQuery"
    },
    "js-cookie@2.1.4#src/js.cookie": {
     "format": "global",
     "exports": "Cookies"
    },
    "lodash@4.17.4#lodash": {
     "format": "global",
     "exports": "_"
    },
    "node-waves@0.7.5#src/js/waves": {
     "format": "global",
     "exports": "Waves"
    }
   },
   "plugins": [
    "steal-css",
    "steal-less",
    "steal-sass",
    "steal-stache",
    "system-json"
   ],
   "npmAlgorithm": "flat"
  },
  "resolutions": {
   "node-waves": "0.7.5",
   "can": "2.3.32",
   "js-cookie": "2.1.4",
   "lodash": "4.17.4",
   "bootstrap-dialog": "1.34.6"
  }
 },
 {
  "name": "steal-css",
  "version": "1.3.1",
  "fileUrl": "./node_modules/steal-css/package.json",
  "main": "css.js",
  "steal": {
   "ext": {
    "css": "steal-css"
   },
   "map": {
    "$css": "steal-css@1.3.1#css"
   }
  },
  "resolutions": {}
 },
 {
  "name": "steal-less",
  "version": "1.2.0",
  "fileUrl": "./node_modules/steal-less/package.json",
  "main": "less.js",
  "steal": {
   "plugins": [
    "steal-css"
   ],
   "envs": {
    "build": {
     "map": {
      "steal-less/less-engine": "steal-less/less-engine-node"
     }
    },
    "server-development": {
     "map": {
      "steal-less/less-engine": "steal-less/less-engine-node"
     }
    },
    "server-production": {
     "map": {
      "steal-less/less-engine": "steal-less/less-engine-node"
     }
    },
    "bundle-build": {
     "map": {
      "steal-less/less-engine": "steal-less/less-engine-node"
     },
     "meta": {
      "steal-less/less": {
       "useLocalDeps": true
      }
     }
    }
   },
   "ext": {
    "less": "steal-less"
   }
  },
  "resolutions": {}
 },
 {
  "name": "steal-sass",
  "version": "1.1.0",
  "fileUrl": "./node_modules/steal-sass/package.json",
  "main": "main.js",
  "steal": {
   "ext": {
    "scss": "steal-sass"
   },
   "plugins": [
    "steal-css"
   ],
   "map": {
    "$sass": "steal-sass@1.1.0#browsersass"
   },
   "envs": {
    "build": {
     "map": {
      "$sass": "steal-sass/nodesass"
     }
    },
    "build-development": {
     "map": {
      "$sass": "steal-sass/nodesass"
     }
    },
    "server-development": {
     "map": {
      "$sass": "steal-sass/nodesass"
     }
    },
    "server-production": {
     "map": {
      "$sass": "steal-sass/nodesass"
     }
    }
   },
   "npmIgnore": {
    "testee": true,
    "mocha": true
   }
  },
  "resolutions": {}
 },
 {
  "name": "steal-stache",
  "version": "3.1.1",
  "fileUrl": "./node_modules/steal-stache/package.json",
  "main": "steal-stache.js",
  "steal": {
   "main": "steal-stache",
   "configDependencies": [
    "live-reload"
   ],
   "npmIgnore": {
    "documentjs": true,
    "testee": true,
    "generator-donejs": true,
    "donejs-cli": true,
    "steal-tools": true
   },
   "npmAlgorithm": "flat",
   "ext": {
    "stache": "steal-stache"
   }
  },
  "resolutions": {}
 },
 {
  "name": "system-json",
  "version": "0.1.0",
  "fileUrl": "./node_modules/system-json/package.json",
  "main": "json.js",
  "resolutions": {}
 },
 {
  "name": "node-waves",
  "version": "0.7.5",
  "fileUrl": "./node_modules/node-waves/package.json",
  "main": "src/js/waves.js",
  "resolutions": {}
 },
 {
  "name": "can",
  "version": "2.3.32",
  "fileUrl": "./node_modules/can/package.json",
  "main": "./can",
  "steal": {
   "ignoreBrowser": true,
   "map": {
    "can@2.3.32#can": "can@2.3.32#can",
    "jquery@2.2.4#jquery": "jquery@2.2.4#dist/jquery",
    "steal-benchmark/steal-benchmark": "steal-benchmark",
    "can@2.3.32#util/vdom/vdom": "@empty"
   },
   "paths": {
    "benchmark/benchmark": "bower_components/benchmark/benchmark.js",
    "dojo/dojo": "util/dojo/dojo-1.8.1.js",
    "yui/yui": "util/yui/yui-3.7.3.js",
    "mootools/mootools": "bower_components/mootools/dist/mootools-core.js",
    "zepto/zepto": "bower_components/zepto/zepto.js"
   },
   "meta": {
    "benchmark/benchmark": {
     "format": "global",
     "exports": "Benchmark"
    },
    "jquery@2.2.4#dist/jquery": {
     "format": "global",
     "exports": "jQuery"
    },
    "dojo/dojo": {
     "format": "global",
     "exports": "Dojo"
    },
    "yui/yui": {
     "exports": "YUI",
     "format": "global",
     "scriptEval": true
    },
    "mootools/mootools": {
     "exports": "MooTools",
     "format": "global",
     "scriptEval": true
    },
    "zepto/zepto": {
     "format": "global",
     "exports": "Zepto"
    },
    "can@2.3.32#util/vdom/vdom": {
     "sideBundle": true
    }
   },
   "ext": {
    "ejs": "can/view/ejs/system",
    "mustache": "can/view/mustache/system",
    "stache": "can/view/stache/system"
   },
   "buildConfig": {
    "map": {
     "can@2.3.32#util/util": "can@2.3.32#util/domless/domless"
    }
   },
   "npmIgnore": {
    "grunt-docco2": true,
    "testee": true,
    "zombie": true,
    "bitovi-tools": true,
    "steal": true,
    "bower": true,
    "steal-tools": true,
    "connect": true,
    "grunt": true,
    "grunt-cli": true,
    "grunt-contrib-clean": true,
    "grunt-contrib-connect": true,
    "grunt-contrib-jshint": true,
    "grunt-contrib-uglify": true,
    "grunt-jsbeautifier": true,
    "grunt-plato": true,
    "grunt-release-steps": true,
    "grunt-shell": true,
    "grunt-simple-mocha": true,
    "grunt-string-replace": true,
    "rimraf": true,
    "lodash": true,
    "browserify": true,
    "grunt-banner": true,
    "documentjs": true,
    "phantomjs-prebuilt": true
   },
   "envs": {
    "server-development": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "exports": "jQuery",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    },
    "server-production": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    },
    "build-development": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "exports": "jQuery",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    }
   },
   "main": "can"
  },
  "browser": {
   "can#can": "can#dist/cjs/can",
   "can#component/component": "can#dist/cjs/component/component",
   "can#construct/construct": "can#dist/cjs/construct/construct",
   "can#map/map": "can#dist/cjs/map/map",
   "can#list/list": "can#dist/cjs/list/list",
   "can#list/promise/promise": "can#dist/cjs/list/promise/promise",
   "can#observe/observe": "can#dist/cjs/observe/observe",
   "can#compute/compute": "can#dist/cjs/compute/compute",
   "can#model/model": "can#dist/cjs/model/model",
   "can#view/view": "can#dist/cjs/view/view",
   "can#view/ejs/ejs": "can#dist/cjs/view/ejs/ejs",
   "can#view/stache/stache": "can#dist/cjs/view/stache/stache",
   "can#view/stache/mustache_core": "can#dist/cjs/view/stache/mustache_core",
   "can#view/href/href": "can#dist/cjs/view/href/href",
   "can#control/control": "can#dist/cjs/control/control",
   "can#route/route": "can#dist/cjs/route/route",
   "can#control/route/route": "can#dist/cjs/control/route/route",
   "can#view/mustache/mustache": "can#dist/cjs/view/mustache/mustache",
   "can#route/pushstate/pushstate": "can#dist/cjs/route/pushstate/pushstate",
   "can#model/queue/queue": "can#dist/cjs/model/queue/queue",
   "can#construct/super/super": "can#dist/cjs/construct/super/super",
   "can#construct/proxy/proxy": "can#dist/cjs/construct/proxy/proxy",
   "can#map/lazy/lazy": "can#dist/cjs/map/lazy/lazy",
   "can#map/delegate/delegate": "can#dist/cjs/map/delegate/delegate",
   "can#map/setter/setter": "can#dist/cjs/map/setter/setter",
   "can#map/attributes/attributes": "can#dist/cjs/map/attributes/attributes",
   "can#map/validations/validations": "can#dist/cjs/map/validations/validations",
   "can#map/backup/backup": "can#dist/cjs/map/backup/backup",
   "can#map/list/list": "can#dist/cjs/map/list/list",
   "can#map/define/define": "can#dist/cjs/map/define/define",
   "can#list/sort/sort": "can#dist/cjs/list/sort/sort",
   "can#control/plugin/plugin": "can#dist/cjs/control/plugin/plugin",
   "can#view/modifiers/modifiers": "can#dist/cjs/view/modifiers/modifiers",
   "can#util/fixture/fixture": "can#dist/cjs/util/fixture/fixture",
   "can#view/bindings/bindings": "can#dist/cjs/view/bindings/bindings",
   "can#view/live/live": "can#dist/cjs/view/live/live",
   "can#view/scope/scope": "can#dist/cjs/view/scope/scope",
   "can#util/tests/tests": "can#dist/cjs/util/tests/tests",
   "can#util/object/object": "can#dist/cjs/util/object/object",
   "can#util/function/function": "can#dist/cjs/util/function/function",
   "can#view/autorender/autorender": "can#dist/cjs/view/autorender/autorender",
   "can#util/domless/domless": "can#dist/cjs/util/domless/domless",
   "can#view/stache/system": "can#dist/cjs/view/stache/system",
   "can#view/mustache/system": "can#dist/cjs/view/mustache/system",
   "can#view/ejs/system": "can#dist/cjs/view/ejs/system",
   "can#util/event": "can#dist/cjs/util/event",
   "can#map/sort/sort": "can#dist/cjs/map/sort/sort",
   "can#util/vdom/build_fragment/build_fragment": "can#dist/cjs/util/vdom/build_fragment/build_fragment",
   "can#util/vdom/document/document": "can#dist/cjs/util/vdom/document/document",
   "can#view/import/import": "can#dist/cjs/view/import/import"
  },
  "resolutions": {
   "can": "2.3.32",
   "jquery": "2.2.4"
  },
  "system": {
   "ignoreBrowser": true,
   "map": {
    "can@2.3.32#can": "can@2.3.32#can",
    "jquery@2.2.4#jquery": "jquery@2.2.4#dist/jquery",
    "steal-benchmark/steal-benchmark": "steal-benchmark",
    "can@2.3.32#util/vdom/vdom": "@empty"
   },
   "paths": {
    "benchmark/benchmark": "bower_components/benchmark/benchmark.js",
    "dojo/dojo": "util/dojo/dojo-1.8.1.js",
    "yui/yui": "util/yui/yui-3.7.3.js",
    "mootools/mootools": "bower_components/mootools/dist/mootools-core.js",
    "zepto/zepto": "bower_components/zepto/zepto.js"
   },
   "meta": {
    "benchmark/benchmark": {
     "format": "global",
     "exports": "Benchmark"
    },
    "jquery@2.2.4#dist/jquery": {
     "format": "global",
     "exports": "jQuery"
    },
    "dojo/dojo": {
     "format": "global",
     "exports": "Dojo"
    },
    "yui/yui": {
     "exports": "YUI",
     "format": "global",
     "scriptEval": true
    },
    "mootools/mootools": {
     "exports": "MooTools",
     "format": "global",
     "scriptEval": true
    },
    "zepto/zepto": {
     "format": "global",
     "exports": "Zepto"
    },
    "can@2.3.32#util/vdom/vdom": {
     "sideBundle": true
    }
   },
   "ext": {
    "ejs": "can/view/ejs/system",
    "mustache": "can/view/mustache/system",
    "stache": "can/view/stache/system"
   },
   "buildConfig": {
    "map": {
     "can@2.3.32#util/util": "can@2.3.32#util/domless/domless"
    }
   },
   "npmIgnore": {
    "grunt-docco2": true,
    "testee": true,
    "zombie": true,
    "bitovi-tools": true,
    "steal": true,
    "bower": true,
    "steal-tools": true,
    "connect": true,
    "grunt": true,
    "grunt-cli": true,
    "grunt-contrib-clean": true,
    "grunt-contrib-connect": true,
    "grunt-contrib-jshint": true,
    "grunt-contrib-uglify": true,
    "grunt-jsbeautifier": true,
    "grunt-plato": true,
    "grunt-release-steps": true,
    "grunt-shell": true,
    "grunt-simple-mocha": true,
    "grunt-string-replace": true,
    "rimraf": true,
    "lodash": true,
    "browserify": true,
    "grunt-banner": true,
    "documentjs": true,
    "phantomjs-prebuilt": true
   },
   "envs": {
    "server-development": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "exports": "jQuery",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    },
    "server-production": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    },
    "build-development": {
     "map": {
      "can/util/vdom/vdom": "can/util/vdom/vdom"
     },
     "meta": {
      "jquery": {
       "format": "global",
       "exports": "jQuery",
       "deps": [
        "can/util/vdom/vdom"
       ]
      }
     }
    }
   },
   "main": "can"
  }
 },
 {
  "name": "js-cookie",
  "version": "2.1.4",
  "fileUrl": "./node_modules/js-cookie/package.json",
  "main": "src/js.cookie.js",
  "resolutions": {}
 },
 {
  "name": "lodash",
  "version": "4.17.4",
  "fileUrl": "./node_modules/lodash/package.json",
  "main": "lodash.js",
  "resolutions": {
   "lodash": "4.17.4"
  }
 },
 {
  "name": "bootstrap-dialog",
  "version": "1.34.6",
  "fileUrl": "./node_modules/bootstrap-dialog/package.json",
  "main": "src/js/bootstrap-dialog.js",
  "resolutions": {
   "bootstrap": "3.3.7"
  }
 },
 {
  "name": "bootstrap",
  "version": "3.3.7",
  "fileUrl": "./node_modules/bootstrap/package.json",
  "main": "./dist/js/npm",
  "jspm": {
   "main": "js/bootstrap"
  },
  "resolutions": {
   "jquery": "2.2.4"
  }
 },
 {
  "name": "jquery",
  "version": "2.2.4",
  "fileUrl": "./node_modules/jquery/package.json",
  "main": "dist/jquery.js",
  "resolutions": {}
 }
], {
 "npmParentMap": {}
});
});