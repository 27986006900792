import "bootstrap-dialog";
import "js-cookie";
import Input from "src/admin-modules/input.adm";
import Component from "can/component/";
import "can/view/stache/";
import ViewModel from "./login.vm";


Component.extend({
    tag: "hos-login",
    template: can.view("src/components/login/login.template.stache"),
    viewModel: ViewModel,
    events: {
        inserted( $el ) {
            "use strict";
            let $body = $el.parent().parent();

            Input.init( $body ).activate();
            Cookies.remove("token", { path: "/" });
            Cookies.remove("JSESSIONID", { path: "/" });
            Cookies.remove("RefreshRate", { path: "/" });
            Cookies.remove("credentials", { path: "/" });
            Cookies.remove("VT-On-Behalf-Of", { path: "/" });

            if ( Cookies.get("unauthorized") ) {
                Cookies.remove("unauthorized", { path: "/" });
            }

        },
        ".select-language click": function( $el ){
            var selectedLang = $($el).attr('code');

            Locale.lang = selectedLang;
            Locale.setCookieLang( selectedLang );
            this.viewModel.attr('lang',selectedLang );
        }
    }
});
