import Map from "can/map/";
import _some from "lodash/some";
import _forEach from "lodash/forEach";
import base64 from "src/helpers/base64/";
import _includes from "lodash/includes";
import "src/helpers/translations";
import "js-cookie";
import UserPreferences from "src/models/user-preference";

const LoginViewModel = Map.extend({
    password: "",
    username: "",
    error: false,

    errorHandler(params) {
        "use strict";

        let $notification = $("#notification");
        this.attr("error", (params.alertType === "error") ? true : false);

        $notification.html(params.message).fadeIn(600);
    },

    getLoginEndPointApi(credential) {
        "use strict";

        return can.ajax({
            url: "/version",
            type: "GET",
            dataType: "json"
        }).then((versionApiData) => {
            can.ajaxSetup({
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("Authorization", `Basic ${credential}`);

                    if (location.hash.split("/")[1] === "push-data") {
                        return;
                    }

                    xhr.setRequestHeader(
                        "X-Client-Identifier",
                        `VT-Portal;${versionApiData.version}`
                    );
                }
            });

            return can.ajax({
                url: "/api/LoginEndpoint",
                type: "GET",
                xhrFields: {
                    withCredentials: true
                }
            });
        });
    },

    getMyUserApi(credential) {
        "use strict";

        return can.ajax({
            url: "/api/v2/myUser",
            type: "GET",
            dataType: "json",
            xhrFields: {
                withCredentials: true
            }
        });
    },

    ABORDNotice: function () {
        "use strict";

        BootstrapDialog.show({
            title: Locale.translate("Reminder"),
            size: "size-small",
            closeByBackdrop: false,

            onhidden() {
                window.location.replace( "app.html" );
            },

            message: Locale.translate("<strong>Note:</strong> <strong> ELD </strong> Mandate takes full effect on <strong> December 16, 2019 </strong>. Please switch all of your vehicles from <strong> AOBRD </strong> to <strong> ELD </strong> Regulation mode before this deadline."),

            buttons: [
                {
                    label: Locale.translate("OK"),
                    cssClass: "btn btn-link btn-save",
                    action: dialog => dialog.close()
                }
            ]
        });
    },

    authenticate(event) {
        "use strict";

        event.preventDefault();

        let username = can.trim(this.attr("username")),
            password = can.trim(this.attr("password")),
            credential = base64.encode(username + ":" + password);

        if (!username) {
            this.errorHandler({
                alertType: "error",
                message: Locale.translate("Username is required.")
            });

            return false;
        }

        if (!password) {
            this.errorHandler({
                alertType: "error",
                message:  Locale.translate("Password is required.")
            });

            return false;
        }

        this.errorHandler({
            alertType: "info",
            message: Locale.translate("Logging in please wait...")
        });

        this.getLoginEndPointApi(credential)
            .then(responseData => {
                if (can.trim(responseData) === "") {
                    this.getMyUserApi(credential)
                        .then(responseData => {
                            let permissions = [
                                "PERM_EDIT_EQUIPMENT",
                                "PERM_IS_SYS_ADMIN",
                                "PERM_IS_SYS_ROOT",
                                "PERM_IS_ACCOUNT_ADMIN",
                                "PERM_IS_USER_ADMIN",
                                "PERM_VIEW_ALL_TERMINALS",
                                "PERM_EDIT_USER_PASSWORDS",
                                "PERM_VIEW_PORTAL_DRIVERS_TAB",
                                "PERM_VIEW_PORTAL_DRIVER_LOGS_TAB",
                                "PERM_EDIT_PORTAL_DRIVER_LOGS_TAB",
                                "PERM_VIEW_PORTAL_VIOLATIONS_TAB",
                                "PERM_VIEW_PORTAL_DVIR_HISTORY_TAB",
                                "PERM_VIEW_PORTAL_REPORTS_TAB",
                                "PERM_EDIT_ALERTS",
                                "PERM_EDIT_DRIVERS",
                                "PERM_VIEW_SUB_ACCOUNTS"
                            ],
                            allowed = false;

                            // Determine if the currently logged in user has no portal access and is a `driver`.
                            const hasPortalAccess = responseData.permissions.some(
                                (item) =>
                                    can.trim(item.name) === "PERM_IS_SYS_ROOT" ||
                                    can.trim(item.name) === "PERM_IS_SYS_ADMIN" ||
                                    can.trim(item.name) === "PERM_IS_USER_ADMIN" ||
                                    can.trim(item.name) === "PERM_EDIT_EQUIPMENT" ||
                                    can.trim(item.name) === "PERM_VIEW_ALL_USERS" ||
                                    can.trim(item.name) === "PERM_IS_FLEET_MANAGER" ||
                                    can.trim(item.name) === "PERM_IS_ACCOUNT_ADMIN" ||
                                    can.trim(item.name) === "PERM_VIEW_SUB_ACCOUNTS"
                            );

                            const isDriver = responseData.permissions.some(
                                (item) => item.name === "PERM_IS_DRIVER"
                            );

                            const isAssetAdmin = responseData.permissions.some(
                                (item) => item.name === "PERM_EDIT_EQUIPMENT"
                            );

                            const isFleetManager = responseData.permissions.some(
                                (item) => item.name === "PERM_IS_FLEET_MANAGER"
                            );

                            const isUserAdmin = responseData.permissions.some(
                                (item) => item.name === "PERM_IS_USER_ADMIN"
                            );

                            const isAccountAdmin = responseData.permissions.some(
                                (item) => item.name === "PERM_IS_ACCOUNT_ADMIN"
                            );

                            if (
                                isDriver &&
                                isAssetAdmin &&
                                !isUserAdmin &&
                                !isFleetManager &&
                                !isAccountAdmin
                            ) {
                                window.location.replace("/driver-portal/app.html");
                                return false;
                            }

                            // Determine user permissions that are allowed to login
                            _forEach(responseData.permissions, item => {
                                if (_includes(permissions, item.name)) {
                                    allowed = true;
                                    credential = {
                                        user: responseData.firstName + " " +
                                            responseData.lastName + (
                                                (responseData.suffix) ?
                                                    " " + responseData.suffix : ""
                                            )
                                    };

                                    // Register a credentials
                                    Cookies.set("credentials", JSON.stringify(credential));

                                    // Clear binding
                                    this.attr("username", "");
                                    this.attr("password", "");

                                    if (!hasPortalAccess) {
                                        this.errorHandler({
                                            alertType: "error",
                                            message: Locale.translate(
                                                "Non-Admin users do not have portal access."
                                            )
                                        });
                                    } else {
                                        this.checkSelectedLanguage( responseData.id );
                                    }

                                    return false;
                                }
                            });
                        },

                        errorResponseData => {
                            this.errorHandler({
                                alertType: "error",
                                message: errorResponseData.responseJSON.message
                            });
                        }
                    );
                }
            },

            errorResponseData => {
                switch (errorResponseData.status) {
                    case 401:
                        if (errorResponseData.responseText.indexOf("User license expired or deactivated") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate("User license expired or deactivated.")
                            });

                            return false;
                        }

                        if (errorResponseData.responseText.indexOf("No user license found") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate("No user license found.")
                            });

                            return false;
                        }

                        if (errorResponseData.responseText.indexOf("AuthenticationError") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate(
                                    "Your email address has not been verified. To verify your email address click the verification link in the welcome email that was sent to you..."
                                )
                            });

                            return false;
                        }

                        if (errorResponseData.responseText.indexOf("Bad credentials") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate(
                                    "Login failed, user credentials does not exist.")
                            });

                            return false;
                        }

                        this.errorHandler({
                            alertType: "error",
                            message: Locale.translate("Incorrect username or password")
                        });
                        break;

                    case 402:
                        if (errorResponseData.responseText.indexOf("Account has been deactivated.") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate("Account has been deactivated.")
                            });

                            return false;
                        }

                        break;

                    case 409:
                        this.errorHandler({
                            alertType: "error",
                            message: Locale.translate("User has been deactivated.")
                        });

                        break;

                    case 500:
                        if (errorResponseData.responseText.indexOf("Your session has timed-out.") >= 0) {
                            this.errorHandler({
                                alertType: "error",
                                message: Locale.translate("Your session has timed-out.")
                            });

                            return false;
                        }

                        this.errorHandler({
                            alertType: "error",
                            message: Locale.translate("Login failed please try again.")
                        });
                        break;
                }
            }
        );
    },

    driverHasPortalAccessNotification() {
        "use strict";

        BootstrapDialog.show({
            title: "Driver Status Removal",
            closeByBackdrop: false,
            size: "size-small",
            message: function () {
                return [
                    Locale.translate("Your user is both a driver and an admin. This will not be supported at the beginning of 2017 Q3. At that time,"),
                    Locale.translate("your user will have its driver status removed. If you want to maintain this user as a driver,"),
                    Locale.translate("please create a separate administrative user and have it remove this user's administrative roles.")
                ].join(" ");
            },

            onhidden: function (dialog) {
                dialog.$modalContent.toggleClass("modal-col-orange");
            },

            buttons: [
                {
                    label: Locale.translate("GOT IT"),
                    cssClass: "btn btn-link waves-effect col-blue",
                    id: "closeBtn",
                    hotkey: 13,
                    action: function (dialog) {
                        dialog.close();
                        self.checkSelectedLanguage( userId );
                    }
                }
            ]
        });
    },

    checkSelectedLanguage: function( userId ) {

        "use strict";

        var self = this;

        UserPreferences.findAll({
            id: userId
        })
        .then( ( resp ) => {

            // check if there is preferred language in user preferences
            var languageResult = _.filter( resp, { key: "com.vistracks.vtlib.PREFERRED_LANGUAGE", userId: userId });
            var myLanguage = Locale.systemLang;
            var langArr = {};

            if ( !_.isEmpty( languageResult ) ) {
                _.forEach( languageResult, function( val ) {
                    myLanguage = val.stringVal;
                    langArr = val;
                });
            }

            if ( _.trim( myLanguage ) === self.lang ) {
                if (!localStorage.getItem("loginCount")) {
                    localStorage.setItem("loginCount", (1 % 5));
                    window.location.replace("app.html");
                    return;
                }

                window.location.replace("app.html");
            } else {
                    BootstrapDialog.show({
                    title: Locale.translate("Change Language"),
                    closable: false,
                    size: "size-small",
                    message: function() {
                        return Locale.translate( "Do you want to keep" ) + " " + Locale.getLangName( this.lang ) + " " + Locale.translate( "as the default language?" );
                    },
                    buttons: [
                        {
                            label: Locale.translate( "YES" ),
                            cssClass: "btn btn-link waves-effect col-blue",
                            id: "yesBtn",
                            hotkey: 13,
                            action: dialog => {
                                dialog.indexedButtons.yesBtn[ 0 ].innerHTML = "<i class='fa fa-refresh fa-spin fa-fw'></i> " + Locale.translate( "Saving..." );

                                window.location.replace( "app.html" );

                                var requestMethod = ( langArr.hasOwnProperty( "stringVal" ) ) ? "PUT" : "POST" ;

                                var preferenceParams = [ {
                                    userId: userId,
                                    key: "com.vistracks.vtlib.PREFERRED_LANGUAGE",
                                    prefType: "STRING_PREF",
                                    stringVal: _.trim( self.lang ),
                                    displayName: "Preferred Language",
                                    isEditable: true
                                } ];

                                var param = {
                                    userPreferences: preferenceParams
                                };

                                if ( requestMethod === "PUT" ) {
                                    preferenceParams[ 0 ].id = langArr.id;
                                    param.id = langArr.id;
                                }

                                var test = new UserPreferences( param );
                                test.save().then(
                                    () => {
                                        dialog.close();
                                        window.location.replace( "app.html" );
                                    },
                                    () => {

                                    }
                                );

                            }
                        },
                        {
                            label: Locale.translate( "NO" ),
                            cssClass: "btn btn-link waves-effect col-blue",
                            id: "noBtn",
                            action: dialog => {
                                dialog.close();
                                window.location.replace( "app.html" );
                            }
                        }
                    ]
                });

            }

        });

    },

    lang: Locale.getCookieLang( true ),

    showLanguages: true,

    languages: [
        {
            code: "en",
            name: "English",
            icon: "src/assets/images/flags/fr-en.png"
        },  {
            code: "fr",
            name: "Français",
            icon: "src/assets/images/flags/flag-fr.png"
        }, {
            code: "es",
            name: "Español",
            icon: "src/assets/images/flags/flag-es.png"
        }
    ],

    define: {
        languageSelect: {
            value: Locale.lang,
            set: function( newVal ) {
                "use strict";
                return newVal;
            }
        }
    }
});

export default LoginViewModel;
